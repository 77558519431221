
.login-label{
    text-align: center;
    font-size:1.8rem;
    font-weight: bold;
    padding: 15px 0px;
    color:#fff
}
.btn-login{
    text-align: center;
    margin:auto;
    width: 50%;
    height: 55px;
}
.App-header .form-view{
    animation: formAnimation 1s ease;
}

@keyframes formAnimation{
    0%{
        opacity: 0;
        transform: translateY(-40%) translateX(-50%)
    }
    100%{
        opacity: 1;
        transform: translateY(-50%) translateX(-50%)
    }
}

@-webkit-keyframes formAnimation{
    0%{
        opacity: 0;
        transform: translateY(-40%) translateX(-50%)
    }
    100%{
        opacity: 1;
        transform: translateY(-50%) translateX(-50%)
    }
}

@media screen and (min-width:768px) and (max-width:1024px){
    .App-header .form-view{
        width: 40%;
    }
}

@media screen and (max-width:600px){
    .App-header .form-view{
        width: 70%;
    }
}
